/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/variable';
@import '~node_modules/ngx-chips/core/styles/core/_core.scss';
@import '@angular/cdk/overlay-prebuilt.css';

$flag-icon-css-path: '~flag-icons/flags' !default;
// @import '~flag-icon-css/sass/flag-icon';

@media print {
  body,
  #icon {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    left: auto;
    right: auto;
    top: 10px;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    // page-break-inside: auto;

    .print-break {
      // page-break-before: always;
      clear: both;
      page-break-after: always;
    }
  }

  .print-container {
    // page-break-before: auto;
  }
}

#wrapper,
body {
  background-color: $white !important;
  font-family: $font-family;
}

.fw-500 {
  font-weight: 500;
}
.cursor-pointer {
  cursor: pointer;
}
.text-underline {
  text-decoration: underline;
}
.logo-ideku {
  height: 28px;
}

.ideku-wrap-page {
  width: 98.5%;
  margin-left: 13px;
  margin-right: -20px;
}

.btn:focus {
  outline: unset;
}

.btn:active {
  outline: unset;
}

.for {
  &-desk {
    display: block;
  }

  &-phone {
    display: none;
  }

  @media only screen and (max-width: 800px) {
    &-phone {
      display: block;
    }

    &-desk {
      display: none;
    }
  }
}

.status-desc {
  font-size: 14px;
  margin-left: 15px;
  color: $dark-grey;
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    margin-left: 10px;
  }
}

// .table-red {
//   width: 100%;
//   border: 0;

//   thead {
//     background-color: $dark-red-3;
//     height: 50px;

//     th {
//       border: 0;
//       padding-left: 20px;
//       padding-right: 20px;
//       color: #fff;
//       font-size: 14px;
//       line-height: 21px;
//       font-weight: 600;
//     }

//     th:first-of-type {
//       border-top-left-radius: 25px;
//     }

//     th:last-of-type {
//       border-top-right-radius: 25px;
//     }
//   }

//   tbody {
//     tr {
//       height: 50px;
//     }

//     tr:nth-child(odd) {
//       background-color: $light-red-2;
//     }

//     tr:nth-child(even) {
//       background-color: $light-red-3;
//     }

//     td {
//       padding-left: 20px;
//       padding-right: 20px;
//       color: $dark-grey;
//       font-size: 14px;
//       line-height: 21px;
//       font-weight: 400;
//     }
//   }
// }

.sub-topbar {
  width: 100%;
  height: 60px;
  background-color: $white;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
  padding-right: 28px;

  .title {
    font-weight: 500;
    color: $grey-1;
    font-size: 16px;
    line-height: 24px;
  }

  .subtitle {
    font-weight: 500;
    color: $grey-1;
    font-size: 14px;
    line-height: 22px;
  }
}

.main-content-container {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 20px 30px 0px 30px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.tbl-red {
  border: 0;
  width: 100%;
  font-size: 14px;
  line-height: 21px;

  thead {
    width: inherit;
    height: 40px;
    background: $dark-red-2;
    font-weight: 500;
    color: $white;

    .th-item {
      height: inherit;
      padding: 0 20px 0 20px;
    }

    .th-item:first-child {
      border-top-left-radius: 5px;
    }

    .th-item:last-child {
      border-top-right-radius: 5px;
    }

    .th-center {
      text-align: center;
    }
  }

  tbody {
    position: relative;
    color: $grey-1;
    font-weight: 400;
    tr {
      height: 51px;
      border-bottom: 1px solid $grey-6;

      .td-item {
        padding: 0 20px 0 20px;

        .icon {
          width: 16px;
          cursor: pointer;
        }
      }

      .td-center {
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .tbl-empty-data {
        text-align: center;
        color: $grey-1;
        font-weight: 600;
        font-size: 15px;
      }

      .item-blur {
        filter: blur(5px);
      }

      &:hover {
        background-color: #f9f2f2;
      }
    }
  }
}

.tbl-white {
  border: 0;
  width: 100%;
  font-size: 14px;
  line-height: 21px;

  thead {
    width: inherit;
    height: 40px;
    background: $white;
    font-weight: 500;
    color: $dark-red-3;
    border-bottom: 1px solid $dark-red-3;

    .th-item {
      padding: 0 20px 0 20px;
    }

    .th-item:first-child {
      border-top-left-radius: 5px;
    }

    .th-item:last-child {
      border-top-right-radius: 5px;
    }
  }

  tbody {
    color: $grey-1;
    font-weight: 400;
    tr {
      height: 51px;
      border-bottom: 1px solid $grey-6;

      .td-item {
        padding: 0 20px 0 20px;
      }

      .tbl-empty-data {
        text-align: center;
        color: $grey-1;
        font-weight: 600;
        font-size: 15px;
      }

      &:hover {
        background-color: #f9f2f2;
      }
    }
  }
}

.tbl-pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: auto;
}

.tbl-mobile-empty-data {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  color: $grey-1;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
}

.monitored-business-container {
  width: 311px;
  height: 40px;
}

.checkbox-idepos {
  height: 20px;
  width: 20px;
  cursor: pointer;
  // accent-color: $blue-2;
}

.field-required {
  cursor: pointer;
  color: $dark-red-3;
}

// Joyride
.joyride-step {
  &__container {
    width: 350px !important;
    border-radius: 4px;
  }

  &__title {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px;
    color: $grey-1 !important;
  }

  &__body {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: $light-grey-4;
    padding: 0px 8px 10px 8px !important;
  }

  &__counter {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px;
    text-align: left;
    color: $grey-1;
  }
}

.shepherd-header {
  background: none !important;
  padding: 0 !important;
  margin-bottom: 8px;

  h3 {
    font-size: 16px;
    font-weight: 600;
  }
}

.shepherd-text {
  margin-bottom: 26px;
  padding: 0 !important;
}

.shepherd-footer {
  padding: 0 !important;
}

.prev-button {
  border: 1px solid #8b1531 !important;
  color: #8b1531 !important;
  background-color: $white;
  padding: 3px 20px !important;
}

.next-button {
  background-color: #8b1531 !important;
  color: $white !important;
  padding: 3px 20px !important;
}

.shepherd-enabled {
  padding: 10px;
}

// ICON
.custom-dot-icon {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: $green-1 2px solid;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $green-1;
  color: $green-1;
  border-color: $green-1;
  &-red {
    background-color: $light-red-11;
    color: $light-red-11;
    border-color: $light-red-11;
  }
  &-yellow {
    background-color: $yellow-3;
    color: $yellow-3;
    border-color: $yellow-3;
  }
  &-yellow {
    background-color: $orange;
    color: $orange;
    border-color: $orange;
  }
}

.custom-disabled {
  .wrapper {
    &.disabled {
      background-color: $light-grey-5 !important;
      cursor: not-allowed;
    }
    &.btn-disabled {
      color: $light-grey-5 !important;
      cursor: not-allowed;
      .btn.btn-border-gradient {
        background-color: #fff;
        color: $light-grey-5 !important;
      }
    }
  }
}

// FORM
.invalid-field{
  font-size: 11px;
  color:$light-red-6;
}
// Intro JS

.custom-disabled {
  .wrapper {
    &.disabled {
      background-color: $light-grey-5 !important;
      cursor: not-allowed;
    }
    &.btn-disabled {
      color: $light-grey-5 !important;
      cursor: not-allowed;
      .btn.btn-border-gradient {
        background-color: #fff;
        color: $light-grey-5 !important;
      }
    }
  }
}

// ***input table name component style***
// this is the container's theme
$foundation-theme: (
  container-border-bottom: none,
  container-border-bottom-focused: none,
  container-height: 40px,
);

// this is the tag's theme
$foundation-tag-theme: (
  background: $light-grey-6,
  background-focused: darken($light-grey-6, 10%),
  background-active: darken($light-grey-6, 10%),
  background-hover: darken($light-grey-6, 10%),
  color: $grey-1,
  color-hover: $grey-1,
  border-radius: 5px,
  font-size: 12px,
);

// this is the delete icon's theme
$foundation-icon-theme: (
  fill: #fff,
  fill-focus: #eee,
  transition: all 0.35s,
);

// apply theme to the container
.input-table-name tag-input .ng2-tag-input.foundation-theme {
  @include tag-input-theme($foundation-theme);
  display: flex;
  align-items: center;
  padding: 0;
}

// apply theme to the tags
.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme tag {
  @include tag-theme($foundation-tag-theme);
  height: 34px;
}

// apply theme to the delete icon
.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme tag delete-icon {
  @include icon-theme($foundation-icon-theme);
}

.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme:first-child,
.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme tag-input-form:first-child,
.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme .ng2-tags-container,
.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme input:first-child {
  width: calc(100% - 21px);
}

.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme .ng2-tags-container {
  align-items: center;
}

.input-multiple-table-name tag-input .ng2-tag-input.foundation-theme .ng2-tag-input__text-input {
  font-size: 14px;
  font-family: Poppins;
}
//--------------------------------

// tooltip
.app-tooltip{
  a{
    text-decoration: underline;
    color: $white;
  }
  a:hover{
    color: #ffffff70;
  }
}
//--------------------------------
