@import './variable';

@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.introjs-overlay {
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 9;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 0.7;
  -webkit-filter: blur(50px);
  filter: blur(50px);
}

.introjs-helperLayer {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  z-index: 99;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background: transparent;

  &* {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;

    &:before {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }

    &:after {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }
  }
}

.introjs-tooltipReferenceLayer {
  border-radius: 6px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  z-index: 100000000;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  box-shadow: 0 5px 12px 0 #707376;
}

.introjs-helperNumberLayer {
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.introjs-arrow {
  border: 5px solid transparent;
  content: '';
  position: absolute;

  &.top {
    top: -10px;
    left: 10px;
    border-bottom-color: #fff;
  }

  &.top-right {
    top: -10px;
    right: 10px;
    border-bottom-color: #fff;
  }

  &.top-middle {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #fff;
  }

  &.right {
    right: -10px;
    top: 10px;
    border-left-color: #fff;
  }

  &.right-bottom {
    bottom: 10px;
    right: -10px;
    border-left-color: #fff;
  }

  &.bottom {
    bottom: -10px;
    left: 10px;
    border-top-color: #fff;
  }

  &.bottom-right {
    bottom: -10px;
    right: 10px;
    border-top-color: #fff;
  }

  &.bottom-middle {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #fff;
  }

  &.left {
    left: -10px;
    top: 10px;
    border-right-color: #fff;
  }

  &.left-bottom {
    left: -10px;
    bottom: 10px;
    border-right-color: #fff;
  }
}

.introjs-tooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  min-width: 250px;
  max-width: 300px;
  -webkit-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  margin: 0 0 0 10px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;
  padding: 10px;

  &-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: $grey-1;
  }

  &-header {
    display: flex;
    color: #404245;
    font-family: 400;
    font-size: 16px;
    font-weight: 'Lato';
    font-style: normal;

    &:after {
      content: '.';
      visibility: hidden;
      display: block;
      height: 0;
      clear: both;
    }
  }
}

.introjs-tooltip-header {
  .introjs-tooltip-title {
    flex-grow: 1;
  }

  .introjs-skipbutton {
    flex-grow: 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    color: #a6383a;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
    text-align: right;

    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }
}

/* Tooltip text */
.introjs-tooltiptext {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: $light-grey-4;
}

/* introjs-tooltipbuttons */
.introjs-tooltipbuttons {
  border-top: 1px solid #fff;
  padding: 12px 0 0 0;
  text-align: right;
  white-space: nowrap;
  font-family: 300;
  font-size: 12px;
  font-weight: 'Lato';
  font-style: normal;
  letter-spacing: 0.5px;
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;

  &:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.introjs-button {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  text-decoration: none;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  zoom: 1;
  border-radius: 5px;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}

.introjs-prevbutton {
  padding: 5px 10px;
  background-color: $white;
  color: #a6383a;
  border: 1px solid #a6383a;
}

.introjs-nextbutton {
  float: right;
  padding: 5px 10px;
  background-image: linear-gradient(110.19deg, #c44345 4.43%, #800c2d 93.84%);
  color: $white;
}

.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;

  & &:focus {
    color: #9e9e9e;
    border-color: #bdbdbd;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none;
  }
}

.introjs-hidden {
  display: none;
}

/* Tooltip bullets */
.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  ul {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    clear: both;
    margin: 0 auto 0;
    padding: 0;
    display: inline-block;

    li {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      list-style: none;
      float: left;
      margin: 0 2px;

      a {
        -webkit-transition: width 0.1s ease-in;
        -o-transition: width 0.1s ease-in;
        transition: width 0.1s ease-in;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        display: block;
        width: 8px;
        height: 8px;
        background: #ccc;
        border-radius: 50%;
        text-decoration: none;
        cursor: pointer;

        &:focus,
        &:hover {
          background-color: #a6383a;
          text-decoration: none;
          outline: none;
          width: 15px;
          border-radius: 5px;
        }

        &.active {
          background-color: #a6383a;
          width: 15px;
          border-radius: 5px;
        }
      }
    }
  }
}
